<template>
  <div class='relative'>
    <v-select
      v-model='selectedInvestment'
      @input='updateSelectedInvestment'
      label='investment_name'
      :options='investmentsSearch'
      :close-on-select='true'
      :class='selectLayoutCSS'
      :disabled='disabled'>
      <template v-slot:option='option'>
        <span>{{ option.investment_name }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import cloneDeep                from 'lodash/cloneDeep'

export default {
  name: 'InvestmentSearch',
  props: ['investmentId', 'customCSSClasses', 'disabled'],
  data () {
    return {
      selectedInvestment: null,
    }
  },
  watch: {
    investmentId: {
      handler: function (newVal) {
        if (newVal > 0) {
          this.selectedInvestment = cloneDeep(this.investmentsSearch.find(investment => investment.id === newVal))
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('investments', [
      'investmentsSearch',
    ]),
    selectLayoutCSS () {
      return (this.customCSSClasses) ? this.customCSSClasses : 'w-64'
    },
  },
  methods: {
    ...mapActions('investments', [
      'getInvestmentsSearch',
    ]),
    updateSelectedInvestment () {
      if (this.selectedInvestment && this.selectedInvestment.id > 0) {
        this.$emit('update-selected-investment-id', this.selectedInvestment.id)
      } else {
        this.$emit('update-selected-investment-id', null)
      }
    },
  },
}
</script>
